import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import './style.scss'
import { debounce } from '../../utils'
import useTheme from '../../hooks/useTheme'
import HeaderPCNew from './pc/index_new'
import HeaderMobileNew from './mobile/index_new'
import { HeaderSelectedProvider } from './hooks/SelectedHook'
import Announcement from '../Announcement'

const Header = ({ menuNew, fontColor }) => {
  const { theme, background } = useTheme()
  const [showShadow, setShowShadow] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'scroll',
      debounce(() => {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop
        setShowShadow(scrollTop > 0)
      }),
      1000
    )
  }, [])

  return (
    <HeaderSelectedProvider menu={menuNew}>
      <Announcement />
      <header
        className={cx('custom-header', `header-${theme}`, {
          'show-shadow': showShadow,
        })}
        style={{ background: background }}
      >
        <nav className="navbar">
          <Link to="/" className="navbar-logo">
            <i className="iconfont z-a-138-logo" />
          </Link>
          <div className="nav-list-wrapper">
            {/* PC导航 */}
            <HeaderPCNew fontColor={fontColor} />

            {/* 移动端 */}
            <HeaderMobileNew />
          </div>
        </nav>
      </header>
    </HeaderSelectedProvider>
  )
}

export default Header
