import React, { useState, useEffect } from 'react'
import './style.scss'

const Announcement = () => {
  const [isVisible, setIsVisible] = useState(() => {
    if (typeof window !== 'undefined') {
      const isHidden = localStorage.getItem('announcementHidden') === 'true'
      const expireDate = new Date('2025-04-30')
      const today = new Date()
      return !isHidden && today <= expireDate
    }
    return true
  })
  const [showModal, setShowModal] = useState(false)

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem('announcementHidden', 'true')
  }

  const handleModalOpen = e => {
    e.preventDefault()
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  if (!isVisible) return null

  return (
    <>
      <div className="announcement-bar">
        <div className="announcement-content">
          严正声明：名为"再惠"的手机应用程序，并非我司的产品。特此提醒社会公众，务必提高警惕，谨防受骗上当，避免遭受资金损失。
          <a
            href="#"
            onClick={handleModalOpen}
            style={{
              marginLeft: '10px',
              color: '#0066cc',
              textDecoration: 'underline',
            }}
          >
            查看详细声明&gt;&gt;
          </a>
        </div>
        <button className="close-button" onClick={handleClose}>
          ×
        </button>
      </div>

      {showModal && (
        <div className="announcement-modal" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="modal-close" onClick={handleModalClose}>
              ×
            </button>
            <div className="modal-image-container">
              <img
                src="https://r.kezaihui.com/client/2025-03-24/image/announcement.png"
                alt="详细声明"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Announcement
